var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-sprint-handler","visible":_vm.isSprintHandlerSidebarActive,"backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"change":function (val) { return _vm.$emit('update:is-sprint-handler-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.sprint['id'])?_c('b-button',{attrs:{"variant":_vm.sprint.isCompleted ? 'outline-success' : 'outline-secondary',"size":"sm"},on:{"click":function($event){_vm.sprint.isCompleted = !_vm.sprint.isCompleted}}},[_vm._v("\n          "+_vm._s(_vm.sprint.isCompleted ? 'Completed' : 'Mark Complete')+"\n        ")]):_c('h5',{staticClass:"mb-0"},[_vm._v("Add Sprint")]),_vm._v(" "),_c('div',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.sprint['id']),expression:"sprint['id']"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-sprint')
              hide()}}}),_vm._v(" "),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",class:{'text-warning': _vm.sprint.isImportant},attrs:{"icon":"StarIcon","size":"16"},on:{"click":function($event){_vm.sprint.isImportant = !_vm.sprint.isImportant}}}),_vm._v(" "),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)],1),_vm._v(" "),_c('div',{staticClass:"px-2 py-1"},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.clearForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Sprint number","label-for":"task-name"}},[_c('validation-provider',{attrs:{"name":"Sprint number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task-name","state":errors.length > 0 ? false : null,"autofocus":"","placeholder":"123","trim":"","type":"number"},model:{value:(_vm.sprint['name']),callback:function ($$v) {_vm.$set(_vm.sprint, 'name', $$v)},expression:"sprint['name']"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Goal","label-for":"task-goal"}},[_c('validation-provider',{attrs:{"name":"Goal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task-goal","state":errors.length > 0 ? false : null,"placeholder":"Goal","trim":""},model:{value:(_vm.sprint['goal']),callback:function ($$v) {_vm.$set(_vm.sprint, 'goal', $$v)},expression:"sprint['goal']"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Project","label-for":"project"}},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"assignee-selector",attrs:{"id":"project","dir":_vm.$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr',"options":_vm.$store.state.osBoard.projects,"state":errors.length > 0 ? false : null,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var title = ref.title;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(title))])]}},{key:"selected-option",fn:function(ref){
              var title = ref.title;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(title))])]}}],null,true),model:{value:(_vm.sprint['project']),callback:function ($$v) {_vm.$set(_vm.sprint, 'project', $$v)},expression:"sprint['project']"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-end mb-1"},[_c('b-form-group',{staticClass:"mb-0 flex-grow-1",attrs:{"label":"Date Start","label-for":"date-start"}},[_c('validation-provider',{attrs:{"name":"Date Start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":_vm.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm',"state":errors.length > 0 ? false : null,"placeholder":"Select date","prefix-class":"xmx","type":"datetime","value-type":"YYYY-MM-DD HH:mm"},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('font-ai',{attrs:{"icon":"fa-regular fa-calendar"}})]},proxy:true},{key:"icon-clear",fn:function(){return [_c('font-ai',{attrs:{"icon":"fa-solid fa-xmark"}})]},proxy:true}],null,true),model:{value:(_vm.sprint['date_start']),callback:function ($$v) {_vm.$set(_vm.sprint, 'date_start', $$v)},expression:"sprint['date_start']"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-end mb-1"},[_c('b-form-group',{staticClass:"mb-0 flex-grow-1",attrs:{"label":"Date End","label-for":"date-end"}},[_c('validation-provider',{attrs:{"name":"Date End"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":_vm.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm',"state":errors.length > 0 ? false : null,"placeholder":"Select date","prefix-class":"xmx","type":"datetime","value-type":"YYYY-MM-DD HH:mm"},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('font-ai',{attrs:{"icon":"fa-regular fa-calendar"}})]},proxy:true},{key:"icon-clear",fn:function(){return [_c('font-ai',{attrs:{"icon":"fa-solid fa-xmark"}})]},proxy:true}],null,true),model:{value:(_vm.sprint['date_end']),callback:function ($$v) {_vm.$set(_vm.sprint, 'date_end', $$v)},expression:"sprint['date_end']"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_vm._v(" "),_c('b-form-group',{attrs:{"label-for":"is-started"}},[_c('validation-provider',{attrs:{"name":"Is Started"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(_vm.sprint['is_started']),callback:function ($$v) {_vm.$set(_vm.sprint, 'is_started', $$v)},expression:"sprint['is_started']"}},[_vm._v(" Is Started")]),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"d-flex my-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v("\n                "+_vm._s(_vm.sprint['id'] ? 'Update' : 'Add ')+"\n              ")]),_vm._v(" "),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v("\n                Reset\n              ")])],1)],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }