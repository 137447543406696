<template>
  <section class="mt-sm-2 mt-md-0 d-flex flex-column">
    <div class="font-weight-bold d-flex mb-1 px-1 align-items-center" style="height: 21px">
      <b-dropdown class="animation" toggle-class="text-decoration-none font-medium-3 p-0 mr-75" variant="link">
        <template #button-content>
          <span>{{ `${$t('Sprint')} ${isSprint ? ' #' + selectedSprint.name : ''}` }}</span>
        </template>
        <b-dropdown-item @click="$emit('new-sprint')">Create a new sprint</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          v-for="(sprintItem, sprintIdx) in sprintsListStore"
          :key="sprintIdx"
          @click="selectSprint(sprintItem)"
        >
          <p class="m-0 font-weight-bold">{{ $t('Sprint') + ' #' + sprintItem.name }}</p>
          <p class="font-small-2 mb-0">
            {{ formatDateTime(sprintItem.date_start) }}
            &nbsp;&#8211;&nbsp;
            {{ formatDateTime(sprintItem.date_end) }}
          </p>
        </b-dropdown-item>
        <b-dropdown-item v-if="sprintsListStore.length === 0 || isLoading" disabled>
          <os-loader />
        </b-dropdown-item>
      </b-dropdown>

      <b-badge class="text-uppercase font-small-2" variant="light-warning">{{ tasks.length }} tasks</b-badge>
      <b-badge
        v-tippy="{content: 'Total estimates', delay: [400, 0]}"
        class="ml-50 font-small-2"
        variant="light-primary"
        >{{ totalEstimates }}
      </b-badge>

      <b-badge
        v-if="checkRange === 1 && !selectedSprint.is_started"
        class="font-small-2 text-uppercase d-flex align-items-center"
        variant="light"
      >
        waiting
      </b-badge>
      <b-badge
        v-if="checkRange === 2 && !selectedSprint.is_started"
        class="font-small-2 text-uppercase d-flex align-items-center"
        variant="light-danger"
      >
        not started
      </b-badge>
      <b-badge
        v-if="checkRange === 2 && selectedSprint.is_started"
        class="font-small-2 text-uppercase d-flex align-items-center"
        variant="light-warning"
      >
        in work
      </b-badge>
      <b-badge
        v-if="checkRange === 3 && !selectedSprint.is_started"
        class="font-small-2 text-uppercase d-flex align-items-center"
        variant="light-success"
      >
        completed
      </b-badge>

      <div v-if="isSprint" class="tw-ml-auto tw-flex tw-items-center tw-gap-2">
        <feather-icon
          v-if="!selectedSprint.is_started"
          class="tw-text-gray-400 tw-cursor-pointer tw-w-5 tw-h-5"
          icon="ArrowRightCircleIcon"
          @click="startSprint(selectedSprint.id)"
        />

        <feather-icon
          v-else
          class="tw-text-gray-400 tw-cursor-pointer tw-w-5 tw-h-5"
          icon="StopCircleIcon"
          @click="stopSprint(selectedSprint.id)"
        />

        <feather-icon
          class="tw-text-gray-400 tw-cursor-pointer tw-w-5 tw-h-5"
          icon="EditIcon"
          @click="$emit('edit-sprint', selectedSprint)"
        />

        <feather-icon
          class="tw-text-gray-400 tw-cursor-pointer tw-w-5 tw-h-5"
          icon="Trash2Icon"
          @click="$emit('delete-sprint', selectedSprint.id)"
        />
      </div>
    </div>
    <div v-if="!isLoading && isSprint" class="tw-flex-grow tw-overflow-hidden">
      <div
        class="tw-flex tw-flex-col tw-border tw-border-gray-200 tw-rounded-2xl tw-pl-2 tw-pr-5 tw-py-6 tw-h-full tw-overflow-hidden"
      >
        <div class="tw-flex tw-justify-between tw-items-start tw-pl-3">
          <div>
            <p class="font-small-3 mb-0">
              {{ formatDateTime(selectedSprint.date_start) }}
              &nbsp;&#8211;&nbsp;
              {{ formatDateTime(selectedSprint.date_end) }}
            </p>
            <p class="font-small-3 text-muted m-0">
              {{ selectedSprint.goal }}
            </p>
          </div>

          <template v-if="['nikita.s', 'oleg.m'].includes($store.state.osBoard.user.username)">
            <RouterLink
              v-if="!isLoadingAnalytics"
              :to="`analytics/sprint?id=${selectedSprint.id}`"
              class="tw-text-gray-400 tw-p-1 tw-rounded-lg hover:tw-bg-gray-100 hover:tw-text-sky-400 tw-transition"
              target="_blank"
            >
              <svg
                class="tw-w-6 tw-h-6"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </RouterLink>

            <p v-else class="tw-animate-pulse">Loading analytics...</p>
          </template>
        </div>
        <div class="tw-flex tw-flex-col tw-flex-grow p-0 m-0 pt-50 tw-overflow-hidden tw-h-full">
          <os-loader v-if="isLoadingTasks" />
          <template v-else>
            <div class="tw-mb-4 tw-mt-2 hover:tw-bg-white tw-border-none tw-ml-3 tw-relative">
              <feather-icon class="text-muted tw-absolute tw-top-3 tw-left-2" icon="PlusIcon" size="18" />
              <b-form-input
                v-model="titleForNewTask"
                class="tw-border tw-bg-slate-100/40 focus:tw-bg-slate-100/0 focus:tw-border-sky-400 tw-border-slate-200/40 tw-rounded-lg tw-shadow-none tw-ps-8 tw-py-0 tw-text-slate-500 tw-text-sm tw-transition"
                placeholder="Create new task"
                type="text"
                @keydown.enter="() => $emit('on-create-task', selectedSprint)"
              />
            </div>
            <draggable
              :group="{name: 'tasks'}"
              :list="tasks"
              animation="150"
              chosenClass="sortable-chosen"
              class="list-group list-group-flush sprint-list custom-scroll tw-pr-2 tw-overflow-auto tw-flex-grow tw-h-full"
              handle=".handle"
              tag="ul"
              @add="addTaskInSprint($event, selectedSprint)"
            >
              <b-list-group-item
                v-for="(task, taskIdx) in tasks"
                :key="taskIdx"
                class="tw-border tw-border-white hover:tw-bg-slate-100/40 hover:tw-border-slate-200/40 tw-rounded-lg"
                dragClass="sortable-drag"
                tag="li"
                @contextmenu="onContextMenu($event, task)"
              >
                <div
                  class="tw-flex tw-justify-between tw-items-center tw-p-0 tw-flex-nowrap tw-border-none tw-rounded-lg"
                >
                  <b-card-text class="title_restriction font-weight-normal mb-0 d-flex flex-grow-1 tw-select-none">
                    <feather-icon
                      class="text-muted mr-50 handle cursor-grab"
                      icon="AlignJustifyIcon"
                      size="20"
                      style="min-width: 20px"
                    />
                    <b-badge :variant="`light-${resolveTypeVariant(task.task_type)}`">
                      <b-spinner v-if="task.id === null" style="height: 12px; width: 12px" />
                      <template v-else>{{ '#' + task.id }}</template>
                    </b-badge>
                    <div class="pl-50 text-truncate">
                      <a
                        :href="task.id !== null ? $router.resolve({name: 'task', params: {id: task.id}}).href : null"
                        class="todo-title-wrapper"
                        style="color: unset"
                      >
                        <span
                          v-tippy="{content: task.subject, delay: [1000, 0]}"
                          :class="{
                            'text-muted': task.is_completed || task.id === null,
                            'text-del': task.is_completed,
                          }"
                          class="planning-task-subject"
                          >{{ task.subject }}</span
                        >
                      </a>
                    </div>
                  </b-card-text>
                  <div class="mr-50">
                    <b-button
                      :variant="`outline-${colorStatus(task.status.status)}`"
                      class="rounded-lg py-25 px-50 font-small-1 font-weight-bolder tw-whitespace-nowrap tw-uppercase"
                      size="sm"
                    >
                      {{ task.status !== null ? $t(task.status.status) : 'Reserve' }}
                    </b-button>
                  </div>
                  <b-badge class="mr-50 font-small-2 tw-select-none" variant="light-success"
                    >{{ formatEstimate(task['estimate']) }}
                  </b-badge>
                  <b-badge class="mr-50 font-small-2 tw-select-none" variant="light-success"
                    >{{ formatEstimate(task['estimate']) }}
                  </b-badge>
                  <div class="d-flex align-items-center flex-nowrap">
                    <b-badge
                      v-if="task['worker_type']"
                      class="mr-50 font-small-2 text-uppercase tw-select-none"
                      variant="light-secondary"
                    >
                      {{ task['worker_type'] }}
                    </b-badge>
                    <b-avatar
                      v-if="task.assigned !== null"
                      v-tippy="{content: task.assigned.title, delay: [300, 0]}"
                      :src="task.assigned.avatar"
                      :style="formatColor($store.state.osBoard.users.find(el => el.id === task.assigned.id || null))"
                      :text="avatarText(task.assigned.title)"
                      class="tw-select-none"
                      size="28"
                      variant="light-primary"
                    />
                    <b-avatar v-else size="28" variant="light-secondary">
                      <feather-icon class="tw-select-none" icon="UserIcon" size="14" />
                    </b-avatar>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </template>
        </div>
      </div>
    </div>

    <!--    <PlanningSprintsSkeleton v-else />-->
    <div v-else class="tw-border tw-border-gray-200 tw-rounded-2xl tw-pl-2 tw-pr-5 tw-py-6 tw-text-center">
      <span class="text-muted">Select sprint</span>
    </div>

    <vue-simple-context-menu
      ref="vueSimpleContextMenu"
      :options="menuDataSprints"
      element-id="mySecondMenu"
      @option-clicked="optionClicked"
    >
    </vue-simple-context-menu>

    <ContextMenu ref="menu" :model="items" />

    <ModalAnalytics :notes-all="notes" :show="showModalChart" :tasks="tasks" @close="showModalChart = false" />
  </section>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCardText,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormInput,
  BListGroupItem,
  BSpinner,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import moment from 'moment/moment';
import SprintsService from '@/services/sprints.service';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import OsLoader from '@/components/OsLoader.vue';
import {colorStatus, formatColor, formatEstimate, resolveTypeVariant} from '@/helpers/handlers';
import {avatarText} from '@core/utils/filter';
import TasksService from '@/services/tasks.service';
import contextMenu from '@/mixins/mixContextMenu';
import ModalAnalytics from '@/components/ModalAnalytics.vue';
import ContextMenu from 'primevue/contextmenu';

export default {
  name: 'PlanningSprints',
  mixins: [contextMenu],
  components: {
    BButton,
    ModalAnalytics,
    BSpinner,
    BFormInput,
    OsLoader,
    BCardText,
    BListGroupItem,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    draggable,
    ContextMenu,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    sprintsList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleForNewTask: '',
      isLoadingTasks: false,
      selectedSprint: {
        name: '',
      },
      tasks: [],
      notes: [],
      showModalChart: false,
      statuses: [],
      selectedTask: null,
      isLoadingAnalytics: false,
    };
  },
  computed: {
    isSprint() {
      // eslint-disable-next-line no-prototype-builtins
      return this.selectedSprint.hasOwnProperty('id');
    },
    totalEstimates() {
      const newArr = this.tasks.map(el => Number(el.estimate));
      if (newArr.length === 0) {
        return '0h 0m';
      } else {
        const total = newArr.reduce((acc, number) => acc + number);
        return this.formatEstimate(total);
      }
    },
    sprintsListStore() {
      return this.$store.state.sprints.sprints;
    },
    items() {
      return [
        {
          label: 'Transfer',
          icon: 'pi pi-fw pi-arrow-right',
          command: this.onClickContextItem,
          type: 'transfer',
          items: [
            {
              label: this.$t('Backlog'),
              type: 'backlogTransfer',
              data: null,
              command: this.onClickContextItem,
            },
            ...this.sprintsListStore
              .filter(sprint => sprint.id !== this.selectedSprint.id)
              .map(sprint => ({
                label: `#${sprint.name}${sprint.goal ? ' ' + sprint.goal : ''}`,
                type: 'sprintTransfer',
                data: sprint,
                command: this.onClickContextItem,
              })),
          ],
        },
        {
          label: this.$t('Copy link'),
          icon: 'pi pi-fw pi-copy',
          type: 'copyLink',
          command: this.onClickContextItem,
        },
        {
          label: this.$t('Delete'),
          icon: 'pi pi-fw pi-trash',
          type: 'delete',
          command: this.onClickContextItem,
        },
      ];
    },
  },
  methods: {
    avatarText,
    formatColor,
    formatEstimate,
    colorStatus,
    resolveTypeVariant,
    onContextMenu(event, task) {
      this.selectedTask = task;
      this.$refs.menu.show(event);
    },
    removeTask() {
      TasksService.deleteTask({id: this.selectedTask.id}).then(async () => {
        this.isTaskHandlerSidebarActive = false;
        this.selectedTask = null;
        await this.getSprintData();
      });
    },
    onShowModalStatistic() {
      this.isLoadingAnalytics = true;
      this.notes = [];
      this.tasks.forEach((item, i) => {
        this.tasks[i].notes = [];
        this.tasks[i].time_elapsed = 0;

        TasksService.getNotes(item.id).then(r => {
          r.data.forEach(el => {
            this.tasks[i].time_elapsed += el.time_elapsed;

            if (this.notes.find(e => e.author.id === el.author.id)) {
              this.notes.find(e => e.author.id === el.author.id).time_elapsed += el.time_elapsed;
            } else {
              this.notes.push({...el, taskQuantity: 1});
            }

            if (this.tasks[i].notes.find(e => e.author.id === el.author.id)) {
              this.tasks[i].notes.find(e => e.author.id === el.author.id).time_elapsed += el.time_elapsed;
            } else {
              this.tasks[i].notes.push(el);
            }
          });

          if (this.tasks.length === i + 1) {
            this.showModalChart = true;
            this.isLoadingAnalytics = false;
          }
        });
      });
    },
    onClickContextItem(event) {
      switch (event.item.type) {
        case 'sprintTransfer':
          TasksService.updateTask(this.selectedTask.id, {sprint: event.item.data.id})
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Status changed',
                  icon: 'BellIcon',
                  text: 'Task moved to another sprint',
                },
              });
            })
            .finally(() => {
              this.getSprintData();
            });
          break;
        case 'backlogTransfer':
          TasksService.updateTask(this.selectedTask.id, {sprint: null})
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Status changed',
                  icon: 'BellIcon',
                  text: 'Task moved to another sprint',
                },
              });
            })
            .finally(() => {
              this.getSprintData();
              this.$emit('update-backlog');
            });
          break;
        case 'copyLink':
          this.$copyText(
            // eslint-disable-next-line no-undef
            process.env.VUE_APP_SITE_URL +
              this.$router.resolve({
                name: 'task',
                params: {id: this.selectedTask.id},
              }).href
          ).then(
            () => {
              this.selectedTask = null;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              });
            },
            () => {
              this.selectedTask = null;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              });
            }
          );
          break;
        case 'delete':
          this.$bvModal
            .msgBoxConfirm('Are you sure?', {
              cancelVariant: 'outline-danger',
            })
            .then(() => {
              this.task = event.item;
              this.removeTask();
            });
          break;
        default:
          break;
      }
    },
    getSprintData() {
      TasksService.getTasksForSprint(this.selectedSprint.id).then(r => {
        this.tasks = r.data;
        this.isLoadingTasks = false;
      });
    },
    selectSprint(sprint) {
      this.isLoadingTasks = true;
      this.selectedSprint = sprint;
      this.getSprintData();
    },
    addTaskInSprint(event) {
      SprintsService.addTaskInSprint(this.selectedSprint.id, {
        id: this.tasks[event.newIndex].id,
      }).then(r => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Status changed',
            icon: 'BellIcon',
            text: r.data.message,
          },
        });
      });
      this.orderingTasks();
    },
    checkRange() {
      const {date_start, date_end} = this.selectedSprint;
      let now = moment().format('YYYY-MM-DD HH:mm:ss');

      if (now < date_start) return 1;
      if (now >= date_start && now <= date_end) return 2;
      if (now > date_end) return 3;
    },
    formatDateTime(date) {
      if (this.$i18n.locale === 'ru') {
        moment.locale('ru');
        return moment(date).format('D MMM YYYY');
      } else {
        moment.locale('en');
        return moment(date).format('MM/DD/YYYY HH:mm');
      }
    },
    startSprint(id) {
      SprintsService.startSprint(id).then(() => {
        this.$emit('get-sprints-for-project');
      });
    },
    stopSprint(id) {
      SprintsService.stopSprint(id).then(() => {
        this.$emit('get-sprints-for-project');
      });
    },
    orderingTasks() {
      let num = 0;
      this.selectedSprint.tasks.forEach(item => {
        item.ordering = num;
        num++;
      });
    },
  },
};
</script>

<style lang="scss"></style>
