<template>
  <section class="md:tw-overflow-y-auto tw-flex tw-flex-col">
    <div class="font-weight-bold d-flex align-items-center mb-1 px-1" style="height: 21px">
      <span class="font-medium-3">{{ $t('Backlog') }}</span>

      <b-badge class="ml-50 text-uppercase font-small-2" variant="light-warning">{{ backlog.length }} tasks</b-badge>
    </div>

    <div
      class="tw-border tw-border-gray-200 tw-rounded-2xl tw-pl-2 tw-pr-5 tw-py-6 tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden"
    >
      <os-loader v-if="isLoading" />
      <template v-else>
        <div class="tw-mb-4 hover:tw-bg-white tw-border-none tw-ml-3 tw-relative">
          <feather-icon class="text-muted tw-absolute tw-top-3 tw-left-2" icon="PlusIcon" size="18" />
          <b-form-input
            v-model="titleForNewTask"
            class="tw-border tw-bg-slate-100/40 focus:tw-bg-slate-100/0 focus:tw-border-sky-400 tw-border-slate-200/40 tw-rounded-lg tw-shadow-none tw-ps-8 tw-py-0 tw-text-slate-500 tw-text-sm tw-transition"
            placeholder="Create new task"
            type="text"
            @keydown.enter="() => $emit('on-create-task', null)"
          />
        </div>
        <draggable
          :group="{name: 'tasks'}"
          :list="backlog"
          animation="150"
          chosenClass="sortable-chosen"
          class="list-group list-group-flush backlog-list custom-scroll tw-pr-2 tw-overflow-auto"
          handle=".handle"
          tag="ul"
          @add="val => $emit('remove-sprint-from-the-task', val)"
        >
          <b-list-group-item
            v-for="(backlogItem, backlogItemIdx) in backlog"
            :key="backlogItemIdx"
            class="tw-border tw-border-white hover:tw-bg-slate-100/40 hover:tw-border-slate-200/40 tw-rounded-lg"
            dragClass="sortable-drag"
            tag="li"
            @contextmenu="onContextMenu($event, backlogItem)"
          >
            <div class="tw-flex tw-justify-between tw-items-center tw-p-0 tw-flex-nowrap tw-border-none tw-rounded-lg">
              <b-card-text class="title_restriction font-weight-normal mb-0 d-flex flex-grow-1 tw-select-none">
                <feather-icon
                  class="text-muted mr-50 handle cursor-grab"
                  icon="AlignJustifyIcon"
                  size="20"
                  style="min-width: 20px"
                />
                <b-badge :variant="`light-${resolveTypeVariant(backlogItem.task_type)}`">
                  <b-spinner v-if="backlogItem.id === null" style="height: 12px; width: 12px" />
                  <template v-else>
                    {{ '#' + backlogItem.id }}
                  </template>
                </b-badge>
                <div class="pl-50 text-truncate">
                  <a
                    :href="
                      backlogItem.id !== null
                        ? $router.resolve({name: 'task', params: {id: backlogItem.id}}).href
                        : null
                    "
                    class="todo-title-wrapper"
                    style="color: unset"
                  >
                    <span
                      v-tippy="{content: backlogItem.subject, delay: [1000, 0]}"
                      :class="{
                        'text-muted': backlogItem.is_completed || backlogItem.id === null,
                        'text-del': backlogItem.is_completed,
                      }"
                      class="planning-task-subject"
                      >{{ backlogItem.subject }}</span
                    >
                  </a>
                </div>
              </b-card-text>
              <div class="d-flex align-items-center flex-nowrap">
                <b-badge
                  v-if="backlogItem['worker_type']"
                  class="mr-50 font-small-2 text-uppercase tw-select-none"
                  variant="light-secondary"
                >
                  {{ backlogItem['worker_type'] }}
                </b-badge>
                <b-avatar
                  v-if="backlogItem.assigned !== null"
                  v-tippy="{content: backlogItem.assigned.title, delay: [300, 0]}"
                  :src="backlogItem.assigned.avatar"
                  :style="formatColor($store.state.osBoard.users.find(el => el.id === backlogItem.assigned.id || null))"
                  :text="avatarText(backlogItem.assigned.title)"
                  size="28"
                  class="tw-select-none"
                  variant="light-primary"
                />
                <b-avatar v-else size="28" variant="light-secondary">
                  <feather-icon icon="UserIcon" size="14" class="tw-select-none" />
                </b-avatar>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </template>
    </div>

    <ContextMenu ref="menu" :model="contextMenuItems" />
  </section>
</template>

<script>
import {BAvatar, BBadge, BCardText, BFormInput, BListGroupItem, BSpinner} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import OsLoader from '@/components/OsLoader.vue';
import {avatarText} from '@core/utils/filter';
import {formatColor, resolveTypeVariant} from '@/helpers/handlers';
import ContextMenu from 'primevue/contextmenu';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TasksService from '@/services/tasks.service';

export default {
  name: 'PlanningBacklog',
  components: {
    ContextMenu,
    BSpinner,
    OsLoader,
    BListGroupItem,
    BAvatar,
    BCardText,
    BBadge,
    BFormInput,
    draggable,
  },
  props: {
    backlog: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleForNewTask: '',
    };
  },
  computed: {
    sprintsListStore() {
      const sprints = this.$store.state.sprints.sprints;
      return sprints ? sprints : [];
    },
    contextMenuItems() {
      return [
        {
          label: this.$t('Add to sprint'),
          icon: 'pi pi-fw pi-arrow-right',
          type: 'addSprint',
          items: this.sprintsListStore.map(sprint => ({
            label: `#${sprint.name}${sprint.goal ? ' ' + sprint.goal : ''}`,
            type: 'addToSprint',
            data: sprint,
            command: this.onClickContextItem,
          })),
        },
        {
          label: this.$t('Copy link'),
          icon: 'pi pi-fw pi-copy',
          type: 'copyLink',
          command: this.onClickContextItem,
        },
        {
          label: this.$t('Delete'),
          icon: 'pi pi-fw pi-trash',
          type: 'delete',
          command: this.onClickContextItem,
        },
      ];
    },
  },
  methods: {
    formatColor,
    avatarText,
    resolveTypeVariant,
    onContextMenu(event, task) {
      this.selectedTask = task;
      this.$refs.menu.show(event);
    },
    removeTask() {
      TasksService.deleteTask({id: this.selectedTask.id}).then(async () => {
        this.isTaskHandlerSidebarActive = false;
        this.selectedTask = null;
        await this.$emit('on-update-backlog');
      });
    },
    onClickContextItem(event) {
      switch (event.item.type) {
        case 'addToSprint':
          TasksService.updateTask(this.selectedTask.id, {sprint: event.item.data.id})
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Status changed',
                  icon: 'BellIcon',
                  text: 'Task moved to another sprint',
                },
              });
            })
            .finally(() => {
              this.$emit('on-update-all');
            });
          break;
        case 'copyLink':
          this.$copyText(
            // eslint-disable-next-line no-undef
            process.env.VUE_APP_SITE_URL +
              this.$router.resolve({
                name: 'task',
                params: {id: this.selectedTask.id},
              }).href
          ).then(
            () => {
              this.selectedTask = null;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              });
            },
            () => {
              this.selectedTask = null;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              });
            }
          );
          break;
        case 'delete':
          this.$bvModal
            .msgBoxConfirm('Are you sure?', {
              cancelVariant: 'outline-danger',
            })
            .then(() => {
              this.task = event.item;
              this.removeTask();
            });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
