<template>
  <div>
    <b-sidebar
      id="sidebar-sprint-handler"
      :visible="isSprintHandlerSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="val => $emit('update:is-sprint-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{hide}">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="sprint['id']"
            :variant="sprint.isCompleted ? 'outline-success' : 'outline-secondary'"
            size="sm"
            @click="sprint.isCompleted = !sprint.isCompleted"
          >
            {{ sprint.isCompleted ? 'Completed' : 'Mark Complete' }}
          </b-button>
          <h5 v-else class="mb-0">Add Sprint</h5>
          <div>
            <feather-icon
              v-show="sprint['id']"
              class="cursor-pointer"
              icon="TrashIcon"
              @click="
                $emit('remove-sprint')
                hide()
              "
            />
            <feather-icon
              :class="{'text-warning': sprint.isImportant}"
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              @click="sprint.isImportant = !sprint.isImportant"
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <div class="px-2 py-1">
          <!-- Body -->
          <validation-observer ref="refFormObserver" #default="{handleSubmit}">
            <!-- Form -->
            <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="clearForm">
              <!-- Name -->
              <b-form-group label="Sprint number" label-for="task-name">
                <validation-provider #default="{errors}" name="Sprint number" rules="required">
                  <b-form-input
                    id="task-name"
                    v-model="sprint['name']"
                    :state="errors.length > 0 ? false : null"
                    autofocus
                    placeholder="123"
                    trim
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Goal -->
              <b-form-group label="Goal" label-for="task-goal">
                <validation-provider #default="{errors}" name="Goal">
                  <b-form-input
                    id="task-goal"
                    v-model="sprint['goal']"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Goal"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Ordering -->
              <!--                            <b-form-group-->
              <!--                                label="Ordering"-->
              <!--                                label-for="task-ordering"-->
              <!--                            >-->
              <!--                                <validation-provider-->
              <!--                                    #default="{ errors }"-->
              <!--                                    name="Ordering"-->
              <!--                                    rules="required"-->
              <!--                                >-->
              <!--                                    <b-form-input-->
              <!--                                        id="task-ordering"-->
              <!--                                        v-model="sprint.ordering"-->
              <!--                                        trim-->
              <!--                                        :state="errors.length > 0 ? false:null"-->
              <!--                                        placeholder="Ordering"-->
              <!--                                    />-->
              <!--                                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                                </validation-provider>-->
              <!--                            </b-form-group>-->

              <!-- Project -->
              <b-form-group label="Project" label-for="project">
                <validation-provider #default="{errors}" name="Project" rules="required">
                  <v-select
                    id="project"
                    v-model="sprint['project']"
                    :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                    :options="$store.state.osBoard.projects"
                    :state="errors.length > 0 ? false : null"
                    class="assignee-selector"
                    label="title"
                  >
                    <template #option="{title}">
                      <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                    </template>

                    <template #selected-option="{title}">
                      <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="d-flex align-items-end mb-1">
                <!-- Date Start -->
                <b-form-group class="mb-0 flex-grow-1" label="Date Start" label-for="date-start">
                  <validation-provider #default="{errors}" name="Date Start">
                    <date-picker
                      v-model="sprint['date_start']"
                      :format="$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select date"
                      prefix-class="xmx"
                      type="datetime"
                      value-type="YYYY-MM-DD HH:mm"
                    >
                      <template v-slot:icon-calendar>
                        <font-ai icon="fa-regular fa-calendar" />
                      </template>
                      <template v-slot:icon-clear>
                        <font-ai icon="fa-solid fa-xmark" />
                      </template>
                    </date-picker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="d-flex align-items-end mb-1">
                <!-- Date End -->
                <b-form-group class="mb-0 flex-grow-1" label="Date End" label-for="date-end">
                  <validation-provider #default="{errors}" name="Date End">
                    <date-picker
                      v-model="sprint['date_end']"
                      :format="$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select date"
                      prefix-class="xmx"
                      type="datetime"
                      value-type="YYYY-MM-DD HH:mm"
                    >
                      <template v-slot:icon-calendar>
                        <font-ai icon="fa-regular fa-calendar" />
                      </template>
                      <template v-slot:icon-clear>
                        <font-ai icon="fa-solid fa-xmark" />
                      </template>
                    </date-picker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <!-- Is Started -->
              <b-form-group label-for="is-started">
                <validation-provider #default="{errors}" name="Is Started">
                  <b-form-checkbox v-model="sprint['is_started']"> Is Started</b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex my-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-1" type="submit" variant="primary">
                  {{ sprint['id'] ? 'Update' : 'Add ' }}
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                  Reset
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BSidebar, VBTooltip} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required, url} from '@validations'
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    // 3rd party packages
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DatePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isSprintHandlerSidebarActive',
    event: 'update:is-sprint-handler-sidebar-active',
  },
  props: {
    isSprintHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    sprint: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  methods: {
    resolveAvatarVariant(tags) {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    },
    onSubmit() {
      if (this.sprint.id) this.$emit('update-sprint', this.sprint)
      else this.$emit('add-sprint', this.sprint)

      // Close sidebar
      this.$emit('update:is-sprint-handler-sidebar-active', false)
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },
    clearForm() {
      this.$emit('clear-sprint-data')
    },
    onContext(ctx) {
      // this.formatted = ctx.selectedFormatted
      this.sprint.due_date = ctx.selectedYMD
    },
    onContextTimeEnd(ctx) {
      this.sprint.time_end = ctx.formatted
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #6f6c7c;
$primary-color: #0095e8;

@import '~vue2-datepicker/scss/index.scss';

.xmx-datepicker {
  width: 100%;

  .xmx-input {
    height: 38px;
  }
}

.b-form-timepicker {
  .dropdown-menu {
    min-width: 8rem;

    .b-time {
      min-width: 105px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.time-block {
  width: 115px;
}
</style>
