import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TasksService from '@/services/tasks.service';

const contextMenu = {
  computed: {
    menuData() {
      return [
        {slug: 'quick-view', name: this.$t('Quick view')},
        {slug: 'quick-edit', name: this.$t('Quick edit')},
        {slug: 'copy-link', name: this.$t('Copy link')},
        {slug: 'delete', name: this.$t('Delete'), class: 'delete-item'},
      ];
    },
    menuDataSprints() {
      return [
        {slug: 'copy-link', name: this.$t('Copy link')},
        {slug: 'delete-sprint-task', name: this.$t('Delete'), class: 'delete-item'},
      ];
    },
  },
  methods: {
    removeTask() {
      TasksService.deleteTask(this.task).then(async () => {
        this.isTaskHandlerSidebarActive = false;
        await TasksService.getTasks();
      });
    },
    handleClick(event, item) {
      this.$refs.vueSimpleContextMenu.showMenu(event, item);
    },
    async optionClicked(event) {
      switch (event.option.slug) {
        case 'quick-edit':
          this.isTaskHandlerSidebarActive = true;
          this.task = event.item;
          break;
        case 'quick-view':
          this.$store.commit('task/GET_TASK', event.item);
          this.$store.commit('task/CHANGE_TASK_VIEW', true);
          await TasksService.getTask(event.item.id);
          break;
        case 'delete':
          this.$bvModal
            .msgBoxConfirm('Are you sure?', {
              cancelVariant: 'outline-danger',
            })
            .then(() => {
              this.task = event.item;
              this.removeTask();
            });
          break;
        case 'delete-sprint-task':
          this.$bvModal
            .msgBoxConfirm('Are you sure?', {
              cancelVariant: 'outline-danger',
            })
            .then(() => {
              this.task = event.item;
              this.removeTask();
            });
          break;
        case 'copy-link':
          this.$copyText(
            // eslint-disable-next-line no-undef
            process.env.VUE_APP_SITE_URL +
              this.$router.resolve({
                name: 'task',
                params: {id: event.item.id},
              }).href
          ).then(
            () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Text copied',
                  icon: 'BellIcon',
                },
              });
            },
            () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Can not copy!',
                  icon: 'BellIcon',
                },
              });
            }
          );
      }
    },
  },
};

export default contextMenu;
