<template>
  <b-modal v-model="show" body-class="tw-px-8 tw-py-5" centered hide-footer hide-header hide-header-close size="xl">
    <div class="d-flex align-items-center justify-content-between tw-mb-6">
      <h3 class="tw-mb-0 tw-font-semibold tw-text-gray-700">{{ $t('Sprint analytics') }}</h3>
      <div class="d-flex align-items-center">
        <font-ai
          v-tippy="{content: $t('Close'), delay: [500, 0]}"
          class="tw-text-gray-600 ml-75 cursor-pointer outline-none text-primary-hover tw-w-5 tw-h-5"
          icon="fa-solid fa-xmark"
          @click="$emit('close')"
        />
      </div>
    </div>

    <div v-if="tasks" class="tw-bg-slate-400/20 tw-rounded-xl tw-p-2">
      <div class="tw-flex tw-justify-between tw-items-center tw-pb-2">
        <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-2">{{ $t('Tasks') }}</h4>
        <div class="table-header-container tw-pr-2">
          <Button class="mr-2" icon="pi pi-plus" label="Expand All" @click="expandAll"> Expand All</Button>
          <Button icon="pi pi-minus" label="Collapse All" @click="collapseAll">Collapse All</Button>
        </div>
      </div>
      <DataTable
        ref="tasks"
        :expandedRows.sync="expandedRows"
        :rows="5"
        :selection.sync="selectedTasks"
        :value="tasks"
        class="p-datatable-sm tw-mb-0"
        paginator
        responsiveLayout="scroll"
        @row-expand="onRowExpand"
      >
        <template #paginatorstart></template>
        <template #paginatorend>
          <div class="tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer" @click="exportCSV('tasks')">
            <span>CSV</span>
            <svg
              class="tw-w-6 tw-h-6"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </template>
        <Column
          :body-style="{paddingLeft: '15px'}"
          :header-style="{width: '40px', paddingLeft: '15px'}"
          selectionMode="multiple"
        ></Column>
        <Column :expander="true" :headerStyle="{width: '40px'}" />
        <Column :headerStyle="{width: '40px'}" header="#">
          <template #body="slotProps">
            <b-badge :variant="`light-${resolveTypeVariant(slotProps.data.task_type)}`">
              {{ '#' + slotProps.data.id }}
            </b-badge>
          </template>
        </Column>
        <Column field="subject" header="Subject" sortable>
          <template #body="slotProps">
            <a
              :href="$router.resolve({name: 'task', params: {id: slotProps.data.id}}).href"
              class="tw-text-gray-600 hover:tw-underline"
              target="_blank"
            >
              {{ slotProps.data.subject }}
            </a>
          </template>
        </Column>
        <Column :header-style="{width: '110px'}" field="task_type" header="Task type" sortable>
          <template #body="slotProps">
            {{ wordToUpperCase(slotProps.data.task_type) }}
          </template>
        </Column>
        <Column :header-style="{width: '130px'}" field="worker_type" header="Worker type" sortable>
          <template #body="slotProps">
            {{ wordToUpperCase(slotProps.data.worker_type) }}
          </template>
        </Column>
        <Column :header-style="{width: '90px'}" field="assigned.title" header="Assigned" sortable></Column>
        <Column :header-style="{width: '90px'}" field="performer.title" header="Performer" sortable></Column>
        <Column :header-style="{width: '80px'}" field="status.status" header="Status" sortable>
          <template #body="slotProps">
            <b-badge
              :variant="`light-${colorStatus(slotProps.data.status.status)}`"
              class="rounded-lg tw-py-1 tw-px-2 tw-text-xs font-weight-bolder tw-whitespace-nowrap tw-uppercase"
            >
              {{ wordToUpperCase(slotProps.data.status.status).split('_').join(' ') }}
            </b-badge>
          </template>
        </Column>
        <Column :header-style="{width: '150px'}" field="estimate" header="Estimate time" sortable>
          <template #body="slotProps">
            {{ getTimeFromMinutes(slotProps.data.estimate) }}
          </template>
        </Column>
        <Column :header-style="{width: '150px'}" field="time_elapsed" header="Time elapsed" sortable>
          <template #body="slotProps">
            {{ getTimeFromMinutes(slotProps.data.time_elapsed) }}
          </template>
        </Column>
        <template #expansion="slotProps">
          <div v-if="slotProps.data.notes.length" class="tw-bg-slate-400/20 tw-rounded-xl tw-p-2 tw-my-2">
            <DataTable :value="slotProps.data.notes" class="p-datatable-sm tw-mb-0" responsiveLayout="scroll">
              <Column :header-style="{width: '90px'}" field="author.title" header="Assigned" sortable></Column>
              <Column :header-style="{width: '90px'}" field="author.worker_type" header="Worker Type" sortable>
                <template #body="slotProps">
                  {{ wordToUpperCase(slotProps.data.author.worker_type) }}
                </template>
              </Column>
              <Column :header-style="{width: '150px'}" field="time_elapsed" header="Time elapsed" sortable>
                <template #body="slotProps">
                  {{ getTimeFromMinutes(slotProps.data.time_elapsed) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </DataTable>
    </div>

    <div v-if="notesAll" class="tw-bg-slate-400/20 tw-rounded-xl tw-p-2 tw-mt-10">
      <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-4">{{ $t('Users') }}</h4>
      <DataTable
        ref="users"
        :rows="5"
        :selection.sync="selected"
        :value="notesAll"
        class="p-datatable-sm tw-mb-0"
        paginator
        responsiveLayout="scroll"
      >
        <Column
          :body-style="{paddingLeft: '15px'}"
          :header-style="{width: '40px', paddingLeft: '15px'}"
          selectionMode="multiple"
        ></Column>
        <Column :header-style="{width: '90px'}" field="author.title" header="User" sortable></Column>
        <Column :header-style="{width: '90px'}" field="author.worker_type" header="Worker Type" sortable>
          <template #body="slotProps">
            {{ wordToUpperCase(slotProps.data.author.worker_type) }}
          </template>
        </Column>
        <Column :header-style="{width: '80px'}" field="time_elapsed" header="Time elapsed" sortable>
          <template #body="slotProps">
            {{ getTimeFromMinutes(slotProps.data.time_elapsed) }}
          </template>
        </Column>
        <Column :header-style="{width: '80px'}" field="taskQuantity" header="Number of tasks" sortable>
          <template #body="slotProps">
            {{ tasks.filter(task => task.assigned.id === slotProps.data.author.id).length }}
          </template>
        </Column>
        <Column :header-style="{width: '80px'}" field="closed_tasks" header="Closed tasks" sortable>
          <template #body="slotProps">
            {{
              tasks.filter(task => task.assigned.id === slotProps.data.author.id && task.status.status === 'done')
                .length
            }}
          </template>
        </Column>
        <template #paginatorstart></template>
        <template #paginatorend>
          <div class="tw-flex tw-items-center tw-gap-x-2 tw-cursor-pointer" @click="exportCSV('users')">
            <span>CSV</span>
            <svg
              class="tw-w-6 tw-h-6"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </template>
      </DataTable>
    </div>

    <div class="tw-bg-slate-400/20 tw-rounded-xl tw-p-2 tw-mt-10">
      <h4 class="tw-mb-0 tw-font-semibold tw-text-gray-700 tw-p-2 tw-pb-4">{{ $t('Total') }}</h4>

      <p class="tw-p-2 tw-font-semibold">
        Time: <span>{{ getTimeFromMinutes(selectedTasks.reduce((s, i) => (s = s + i.time_elapsed), 0)) }}</span>
      </p>
    </div>
  </b-modal>
</template>

<script>
import {BBadge, BModal} from 'bootstrap-vue';
import {colorStatus, formatEstimate, resolveTypeVariant, wordToUpperCase} from '@/helpers/handlers';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import DataTable from 'primevue/datatable/DataTable';
import Column from 'primevue/column/Column';
import {getTimeFromMinutes} from '@/assets/scripts/scripts';

export default {
  components: {BBadge, BModal, DataTable, Column},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    notesAll: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {key: 'user', sortable: true},
        {key: 'workerType', sortable: true},
        {key: 'hours', sortable: true},
        {key: 'taskQuantity', sortable: true},
      ],
      fieldsTasks: [
        {key: 'user', sortable: true},
        {key: 'workerType', sortable: true},
        {key: 'hours', sortable: true},
        {key: 'taskQuantity', sortable: true},
      ],
      items: [],
      selected: [],
      selectedTasks: [],
      expandedRows: [],
    };
  },
  methods: {
    formatEstimate,
    wordToUpperCase,
    getTimeFromMinutes,
    resolveTypeVariant,
    colorStatus,
    exportCSV(ref) {
      this.$refs[ref].exportCSV();
    },
    onRowExpand(event) {
      console.log(event.data);
    },
    expandAll() {
      this.expandedRows = this.tasks.filter(p => p.time_elapsed);
      this.$toast.add({severity: 'success', summary: 'All Rows Expanded', life: 3000});
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({severity: 'success', summary: 'All Rows Collapsed', life: 3000});
    },
  },
};
</script>

<style lang="scss">
.p-component {
  font-family: 'Montserrat', serif;

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > th {
    color: unset;
  }
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #f3f4f6;
}

.p-checkbox {
  box-shadow: none !important;

  .p-checkbox-box {
    border-radius: 6px;
    box-shadow: none;
  }
}

.p-datatable .p-sortable-column.p-highlight {
  color: #0ea5e9;

  &:hover {
    color: #0ea5e9;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0ea5e9;
  background-color: #0ea5e9;
  box-shadow: none;
  padding: 4px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #0ea5e9;
  color: #fff;
  border-radius: 8px;
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 8px;
  min-width: 2rem;
  height: 2rem;
  box-shadow: none;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  &:first-child {
    border-top-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
  }
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 0;
}

.p-contextmenu {
  position: fixed !important;
  display: unset !important;
}

.b-table-select-multi {
  .b-table-row-selected {
    > td {
      background-color: #dadaea;
    }
  }

  td,
  th {
    padding: 0.42rem 0.8rem;
  }
}
</style>
