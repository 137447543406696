<template>
  <div class="sprints">
    <HeaderPages :title="$t('Planning') || ''" />

    <div class="tw-flex tw-flex-grow tw-gap-x-6 tw-w-full tw-overflow-hidden max-md:tw-flex-col max-md:tw-overflow-y-auto max-md:tw-h-full">
      <PlanningBacklog
        ref="backlog"
        :backlog="backlog"
        :is-loading="isLoadingBacklog"
        class="tw-min-w-5/12 tw-w-5/12 max-md:tw-w-full"
        @on-create-task="onCreateTask"
        @on-update-backlog="getTasksForBacklog"
        @on-update-all="getData"
        @remove-sprint-from-the-task="removeSprintFromTheTask($event)"
      />

      <PlanningSprints
        ref="sprints"
        :is-loading="isLoadingSprints"
        :sprints-list="sprintsList"
        class="tw-min-w-7/12 tw-w-7/12 max-md:tw-w-full max-md:tw-mt-6"
        @delete-sprint="deleteSprint"
        @new-sprint="openNewSprint"
        @edit-sprint="editSprint"
        @get-sprints-for-project="getSprintsForProject"
        @update-backlog="getTasksForBacklog"
        @on-create-task="onCreateTask"
      />
    </div>

    <PlanningHandlerSidebar
      v-model="isSprintHandlerSidebarActive"
      :is-sprint-handler-sidebar-active.sync="isSprintHandlerSidebarActive"
      :sprint="sprintEdit"
      @clear-sprint-data="clearSprintData"
      @remove-sprint="deleteSprint"
      @add-sprint="addSprint"
      @update-sprint="updateSprint"
    />
  </div>
</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import moment from 'moment';
import ProjectsService from '@/services/projects.service';
import SprintsService from '@/services/sprints.service';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import TasksService from '@/services/tasks.service';
import PlanningHandlerSidebar from '@/views/Planning/PlanningHandlerSidebar.vue';
import HeaderPages from '@/components/HeaderPages.vue';
import PlanningBacklog from '@/views/Planning/PlanningBacklog.vue';
import PlanningSprints from '@/views/Planning/PlanningSprints.vue';
import dayjs from 'dayjs';

export default {
  components: {
    PlanningSprints,
    PlanningBacklog,
    HeaderPages,
    PlanningHandlerSidebar,
  },
  data() {
    return {
      isSprintHandlerSidebarActive: false,
      backlog: [],
      isLoadingBacklog: true,
      isLoadingSprints: true,
      sprints: [],
      sprintsList: [],
      sprintEdit: {
        date_end: moment().format('YYYY-MM-DD HH:mm'),
        date_start: moment().format('YYYY-MM-DD HH:mm'),
        time_end: '00:00',
        time_start: '00:00',
        goal: '',
        id: null,
        is_started: false,
        length: null,
        name: '',
        ordering: null,
        project: null,
      },
      dragOptions: {},
      collapses: [],
    };
  },
  mounted() {
    ProjectsService.getProjects();
    if (this.currentProject.id) {
      this.getSprintsForProject();
      TasksService.getTasksForBacklog(this.currentProject.id, true).then(r => {
        if (r.status === 200) {
          this.backlog = r.data;
          this.isLoadingBacklog = false;
        }
      });
    }
  },
  computed: {
    currentProject() {
      return this.$store.state.osBoard.selectedProject;
    },
  },
  watch: {
    currentProject() {
      if (this.currentProject.title) {
        this.$refs.sprints.selectedSprint = {
          name: '',
        };
        this.sprintsList = [];
        this.isLoadingBacklog = true;
        this.isLoadingSprints = true;
        this.getData();
      }
    },
    sprints() {
      this.sprintsList = this.sprints.map(item => ({
        ...item,
        goal: item.goal,
        id: item.id,
        is_started: item.is_started,
        length: item.length,
        name: item.name,
        ordering: item.ordering,
        project: item.project,
        tasks: item.tasks,
      }));
      // this.orderingSprints()
    },
  },
  methods: {
    getData() {
      this.getSprintsForProject();
      this.getTasksForBacklog();
    },
    getTasksForBacklog() {
      TasksService.getTasksForBacklog(this.currentProject.id, true)
        .then(r => {
          if (r.status === 200) {
            this.backlog = r.data;
            this.isLoadingBacklog = false;
          }
        })
        .catch(() => (this.isLoadingBacklog = false));
    },
    onCreateTask(sprint = null) {
      const taskData = {
        subject: this.$refs[sprint ? 'sprints' : 'backlog']['titleForNewTask'],
        // id: null,
        status: 'new',
        task_type: 'Task',
        project: this.currentProject,
        due_date: dayjs().format('YYYY-MM-DD'),
        due_time: '00:00',
        duration: 0,
        performer: this.$store.state.osBoard.user,
        is_inbox: false,
        assigned: null,
      };

      if (sprint !== null) {
        taskData['sprint'] = sprint.id;
        // const i = this.sprintsList.findIndex(el => el.id === sprint.id)
        this.$refs.sprints.tasks.push(taskData);
        this.$refs.sprints.titleForNewTask = '';
      } else {
        this.backlog.push(taskData);
        this.$refs.backlog.titleForNewTask = '';
      }

      delete taskData.performer;

      TasksService.createTask(taskData).then(r => {
        TasksService.createDescription(r.data.id, {
          type: 'text',
          string: '',
          ordering: 0,
          img: {
            file: null,
            url: null,
            description: '',
          },
          todoList: [],
          code: {
            lang: '',
            code: '',
          },
        });
        sprint === null ? this.getData() : this.$refs.sprints.getSprintData();
      });
    },
    removeSprintFromTheTask(event) {
      SprintsService.removeSprintFromTheTask(this.$refs.sprints.selectedSprint.id, {
        id: this.backlog[event.newIndex].id,
      }).then(r => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Status changed',
            icon: 'BellIcon',
            text: r.data.message,
          },
        });
      });
    },
    openNewSprint() {
      let days = this.$store.state.sprints.defaultDays;
      this.isSprintHandlerSidebarActive = !this.isSprintHandlerSidebarActive;
      if (!this.isSprintHandlerSidebarActive) {
        this.sprintEdit = {
          date_end: moment().add(days, 'days').format('YYYY-MM-DD 00:00'),
          date_start: moment().format('YYYY-MM-DD 00:00'),
          time_end: '00:00',
          time_start: '00:00',
          goal: '',
          id: null,
          is_started: false,
          length: null,
          name: '',
          ordering: null,
          project: null,
        };
      }
    },
    editSprint(data) {
      this.sprintEdit = data;
      this.isSprintHandlerSidebarActive = !this.isSprintHandlerSidebarActive;
    },
    addSprint() {
      const {name, date_start, is_started, date_end, goal, ordering, project} = this.sprintEdit;
      let data = {
        name,
        date_start,
        is_started,
        date_end,
      };
      if (goal) data['goal'] = goal;
      if (ordering) data['ordering'] = ordering;
      if (project) data['project'] = project.id;

      SprintsService.createSprint(data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: '👍 Sprint created!',
            variant: 'success',
          },
        });
        this.clearSprintData();
        this.getSprintsForProject();
      });
    },
    getSprintsForProject() {
      SprintsService.getSprintsForProject(this.currentProject.id).then(r => {
        if (r.status === 200) {
          const isStarted = r.data.find(sprint => sprint.is_started);
          if (isStarted) this.$refs.sprints.selectSprint(r.data.find(sprint => sprint.is_started));
          this.$store.commit('sprints/GET_SPRINTS', r.data);
          this.isLoadingSprints = false;

          let sprint = this.$refs.sprints.selectedSprint;
          // eslint-disable-next-line no-prototype-builtins
          if (sprint.hasOwnProperty('id')) {
            const i = r.data.findIndex(el => el.id === sprint.id);
            this.$refs.sprints.selectedSprint = r.data[i];
          }
        }
      });
    },
    updateSprint() {
      SprintsService.updateSprint({
        ...this.sprintEdit,
        project: this.sprintEdit.project.id,
      }).then(() => {
        this.clearSprintData();
      });
    },
    deleteSprint(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            SprintsService.deleteSprint(id).then(() => {
              this.getSprintsForProject();
            });
          }
        });
    },
    clearSprintData() {
      this.sprintEdit = {
        date_end: moment().format('YYYY-MM-DD 00:00'),
        date_start: moment().format('YYYY-MM-DD 00:00'),
        time_end: '00:00',
        time_start: '00:00',
        goal: '',
        id: null,
        is_started: false,
        length: null,
        name: '',
        ordering: null,
        project: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.create-task {
  border: 0;
  outline: 0;
  border-radius: 0 !important;
  flex-grow: 1;
  color: #6e6b7b;
  box-shadow: unset !important;
  padding: 0;
  height: 29px;

  &::placeholder {
    color: #b9b9c3;
  }
}

.sprints {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dropdown-toggle::after {
    background-image: url('../../assets/images/icons/chevron-down.svg');
    top: 0;
    right: 0;
    left: 0.3rem;
  }
}

.board .card {
  margin-bottom: 1rem;
}

.sortable-chosen {
  color: #6e6b7b !important;
}

.title_restriction {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  word-break: break-all;
  margin-right: 0.5rem;
}

.p-splitter {
  width: 100%;
  border: unset;
  background: unset;
  border-radius: unset;
  color: unset;

  .p-splitter-panel:first-child {
    min-width: 35%;
  }

  .p-splitter-panel:last-child {
    min-width: 35%;
  }

  .p-splitter-gutter {
    background-color: #e3eaef;
    margin-left: 10px;
    border-radius: 3px;
    width: 5px !important;
    transition: all 0.3ms ease;

    .p-splitter-gutter-handle {
      background-color: #c9d3da;
      border-radius: 3px;
    }

    &.p-splitter-gutter-resizing {
      background-color: #c9d3da;
    }
  }
}

.backlog-list,
.sprint-list {
  overflow-y: scroll;
  padding: 0 14px 0 8px;
  //margin-right: -10px;
  //border-radius: 0 !important;

  .list-group:hover {
    background: unset;
  }

  .list-group-item {
    padding: 0.35rem 0.65rem;

    .planning-task-subject {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(.text-muted) {
        cursor: pointer;
      }

      &:not(.text-muted):hover {
        text-decoration: underline;
      }
    }

    &:hover {
      background-color: #f6f8fa;

      input {
        background-color: #f6f8fa;
      }
    }
  }

  .sortable-ghost {
    background-color: #0000;

    .card-header {
      background-color: #0000;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h4 {
      margin: 0;
    }
  }
}

.backlog-list__ghost {
  opacity: 0.2;
  background-color: unset;
}

.sortable-drag {
  color: red;
  transform: rotate(5deg);
}

.sprint-list-desc {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 10px;
  color: #c1c1c9;
}

.remove-sprint {
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  right: 33px;
  transition: color 0.5s ease;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: $danger !important;
  }
}

.edit-sprint {
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  right: 58px;
  transition: color 0.5s ease;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: $primary !important;
  }
}

.start-sprint {
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  right: 84px;
  transition: color 0.5s ease;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: $success !important;
  }
}

.stop-sprint {
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  right: 84px;
  transition: color 0.5s ease;
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    color: $warning !important;
  }
}

.sprint-drop-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.38rem 1.6rem;

  &__drag {
    display: none;

    &.show {
      display: block;
      margin: 0 6.5rem 0 1rem;
      flex-grow: 1;
      height: 40px;
      border: 1px dashed #d5d5d5 !important;
      border-radius: 0.8rem !important;
      font-size: 12px;

      .list-group-item {
        padding: 0.25rem 0.65rem;
      }
    }

    &.show-collapse {
      width: 100%;
      display: block;
      height: 40px;
      margin-bottom: 1.4rem;
      border: 1px dashed #d5d5d5 !important;
      border-radius: 0.8rem !important;
      font-size: 12px;
    }
  }

  &.show {
    padding: 0.7rem 1.6rem;
    margin-top: 0;
    border: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .board .card:hover {
    transform: translateY(-5px);
    box-shadow: rgb(34 41 47 / 25%) 0 4px 25px 0;
  }
}
</style>